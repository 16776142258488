body {
  font-family: "Open Sans", sans-serif !important;
  color: #474747 !important;
}

#body-div {
  overflow-x: hidden;
}

a {
  color: #474747;
}

a:hover {
  color: white;
  text-decoration: none;
}

#main-div {
  background-color: #aaabb8;
  margin-right: -15px;
  margin-left: -15px;
  width: 100vm;
}

.row {
  text-align: center;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
  overflow: hidden;
}

.row-odd {
  background-color: #2e9cca;
  padding: 10px;
}

.row-even {
  background-color: #c0c0c0;
}

.inside-row-div {
  justify-content: center;
  min-height: 600px;
  margin: 3rem 2rem;
  display: flex;
}

.inside-row-div h4 {
  font-size: 20px;
  color: white;
  margin-bottom: 2rem;
}

.available {
  color: green;
}

.inside-row-div h3 {
  width: 100%;
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 100;
  border-radius: 20px;
}

.row-odd h3, .row-even h3 {
  color: white;
  font-size: 50px;
  font-weight: 500;
  /* background-color: #2ea1d3; */
}

/* Bootstrap General */

/* NavBar */

.navbar-main {
  display: block;
  background-color: #2e9cca;
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
  height: 70px;
  padding: 2px 0;
  margin-bottom: -5px;
}

.navbar-main .social-content {
  position: absolute;
  border-radius: 0 0 0 20px;
  right: 0;
  top: 0;
}

/* SocialBar.js */

.social-content {
  background-color: white;
  border-radius: 20px;
  width: 300px;
  padding: 0.5rem;
  transition: 1s;
  margin: auto;
}

.social-content:hover {
  border-radius: 0;
}

.social-content .social-item {
  display: inline-block;
  transform-style: preserve-3d;
  font-size: 30px;
  margin: 0 2rem;
}

.social-item .fa-git {
  color: black;
}

.social-item .fa-linkedin {
  color: #0e76a8;
}

.social-item .fa-envelope {
  color: black;
}

.social-item:hover {
}

/* Bounce In */
.social-item {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.social-item:hover,
.social-item:focus,
.social-item:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Hello.js */

.row-top {
  height: 700px;
}

.profile-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.profile-div img {
  border-radius: 50%;
  width: 200px;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  margin-bottom: 2rem;
}

.hello-container-div {
  margin-top: 5rem;
}

.hello-container-div h1,
.hello-container-div h3,
.hello-container-div h4 {
  font-weight: 100;
}

.hello-container-div h1 {
  font-size: 2.5rem;
}

.hello-container-div h1 span {
  color: #de9800;
  font-weight: 500;
}

.hello-container-div h2 {
  background-color: #005a80;
  color: #aaabb8;
  font-size: 1.3rem;
  padding: 0.3rem;
  width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

#bio-div {
  width: 600px;
  text-align: left;
  margin: 6rem 2rem 0 2rem;
  padding: 3rem 4rem;
  border-radius: 30px;
  background-color: #474747;
  color: #aaabb8;
}

#bio-div p {
  font-size: 14px;
}

/* About.js */

.about-content {
  margin: 0 2rem;
  text-align: center;
  color: white;
  height: 600px;
  width: 600px;
  border-radius: 30px;
  background-color: brown;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
  padding: 3rem 4rem;
}

.about-content p {
  border-right: 0.15em solid orange;
  font-family: "Courier";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .about-content p {
    font-size: 10px;
    margin-left: 4rem;
  }

  #bio-div p, h5 {
    margin: 1rem 4rem;
  }
}

.about-content p:nth-child(1) {
  width: 24ch;
  -webkit-animation: type 2s steps(31, end);
  animation: type 3s steps(31, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.about-content p:nth-child(2) {
  width: 47ch;
  opacity: 0;
  -webkit-animation: type2 3s steps(50, end);
  animation: type2 3s steps(50, end);
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.about-content p:nth-child(3) {
  width: 48ch;
  opacity: 0;
  -webkit-animation: type2 2s steps(50, end);
  animation: type2 2s steps(50, end);
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.about-content p:nth-child(4) {
  width: 28ch;
  opacity: 0;
  -webkit-animation: type2 2s steps(28, end);
  animation: type2 2s steps(28, end);
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.about-content p:nth-child(5) {
  position: absolute;
  bottom: 200px;
  width: 15ch;
  opacity: 0;
  -webkit-animation: type2 2s steps(15, end);
  animation: type2 2s steps(15, end);
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

/* Projects.js */

.project-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem 7rem;
}

#projects-notes-div {
  padding: 0 0 40px 0
}

.icons img, .project-no-logo-div {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit:contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  background-color: #222 ;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.icons img:hover, .project-no-logo-div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.project-div .card {
  background-color: unset;
  border: none;
  border-radius: 50%;
  margin-bottom: 4rem;
}

.accordion {
  background-color: transparent;
}

.card-body {
  background-color: #00567a;
  color: white;
  border-radius: 20px;
  text-align: left;
  font-weight: 100;
}

.card-body h4,
.card-body h5 {
  color: #2e9cca;
  font-weight: 100;
}

.card-body ul {
  list-style-type: none;
}

.project-div .card-header {
  background-color: transparent;
  border: none;
  padding-bottom: 30px;
}

/* Knowledge.js */

.knowledge-item {
  overflow: auto;
  width: 250px;
  margin: 20px auto 0 auto;
  background-color: #222 !important;
  border-radius: 20px;
}

.knowledge-normal {
  height: 280px;
}

.knowledge-mini {
  height: 50px;
  display: flex;
  justify-content: center;
}

.knowledge-item img {
  max-width: 200px;
}

.knowledge-logo-container {
  margin: 10% auto 5%;
  width: 250px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.knowledge-logo-container img {
  object-fit: scale-down;
  height: 100%;
  width: 100%;

}

.knowledge-item-title {
  word-wrap: break-word;
  line-height: 1em;
  max-height: 2em;
  max-width: 200px;
  margin: auto;
  font-size: 20px;
  font-weight: 200;
  color: #aaabb8;
  justify-content: center;
}

/* -- React -- */

.react-logo {
  display: inline-block;
  border-radius: 50%;
  border: 10px solid #00d8ff;

  animation-name: rotate;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.react-logo,
.react-logo:before,
.react-logo:after {
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #00d8ff;
}

.nucleo,
.react-logo,
.react-logo:before,
.react-logo:after {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nucleo {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #00d8ff;
}

.react-logo:before,
.react-logo:after {
  content: "";
}

.react-logo:after {
  transform: rotate(-57deg);
}

.react-logo:before {
  transform: rotate(57deg);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* React over */

.ejs-logo-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #a91e50;
  font-size: 80px;
}
.express-logo-text {
  font-family: "Helvetica", sans-serif;
  font-weight: 100;
  font-size: 60px;
  color: beige;
}

.library-name {
  margin: auto;
  font-weight: 100;
  font-size: 1.5rem;
  color: #aaabb8;
}

/* Contact.js */

.contact-content h3 {
  margin-bottom: 2rem;
  padding: 0 1rem;
}

/* CONTACT text */

.contact-content p {
  color: #fff;
  font-family: Avenir Next, Helvetica Neue, Helvetica, Tahoma, sans-serif;
  font-size: 1em;
  font-weight: 700;
}

.contact-content p span {
  display: inline-block;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 500;
  perspective: 500;
  -webkit-font-smoothing: antialiased;
}

.contact-content p span::before,
.contact-content p span::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
  content: attr(data-text);
}

.contact-content p span::before {
  z-index: 1;
  color: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1.1, 1) skew(0deg, 20deg);
  transform: scale(1.1, 1) skew(0deg, 20deg);
}

.contact-content p span::after {
  z-index: 2;
  color: #2e9cca;
  text-shadow: -1px 0 1px #2e9cca, 1px 0 1px rgba(0, 71, 102, 0.8);
  -webkit-transform: rotateY(-40deg);
  transform: rotateY(-40deg);
}

.contact-content p span:hover::before {
  -webkit-transform: scale(1.1, 1) skew(0deg, 5deg);
  transform: scale(1.1, 1) skew(0deg, 5deg);
}

.contact-content p span:hover::after {
  -webkit-transform: rotateY(-10deg);
  transform: rotateY(-10deg);
}

.contact-content p span + span {
  margin-left: 0.3em;
}

@media (max-width: 768px) {
  .contact-content p {
    font-size: 3em;
  }
}

@media (min-width: 768px) {
  .contact-content p {
    font-size: 4em;
  }
}

@media (min-width: 992px) {
  .contact-content p {
    font-size: 5em;
  }
}

@media (min-width: 1200px) {
  .contact-content p {
    font-size: 8em;
  }
}

#newPortfolioRedirectDiv {
  height: 200px;
}

#newPortfolioRedirectText {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  
}

#newPortfolioBtn {
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #2e9cca;
  color: white;

  font-size: 20px;

}

/* Footer */

footer {
  background-color: #2e9cca;
  text-align: center;
  height: 2rem;
}

.footer-main p {
  margin: 0;
}
